.meaning {
    background-color: black;
    color: white;
}

.wrapUvod {
    position: relative;
    z-index: 666;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    padding-bottom: 10rem;
    padding-top: 5rem;

}

.imageBack {
    position: absolute;
    bottom: 0;
    z-index: 111;
    top: 0;
    left: 0;
    right: 0;
    background: url('../../src/images/vyznam/ad.jpg');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
}

.imageBackOverlay {
    position: absolute;
    bottom: 0;
    z-index: 222;
    top: 0;
    left: 0;
    right: 0;
    background: rgb(0, 0, 0);
    background: linear-gradient(360deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 70%, rgba(0, 0, 0, 1) 90%);
}

.brake {
    margin-top: 10px;
    text-align: center;
}

.uvod {
    font-size: 22px;
    margin-bottom: 30px;
    position: relative;
    z-index: 666;
    padding-top: 11rem;
    padding-left: 2rem;
    padding-right: 2rem;
}

/* Sekce Chaos v řádu */
#chaosInOrder1 {
    background-color: black;
    /* Tmavší modré bloky pro jednotlivé sekce */
    padding: 20px;
    margin-bottom: 20px;

}

#wrapChaosInOrder1 {
    display: flex;
    flex-direction: row;
    gap: 2rem;
    flex-wrap: wrap;
}

.chaosInOrder1_p {
    display: flex;
    justify-content: center;
    gap: 3rem;
    padding-left: 2rem;
}

.chaosInOrder1_p>* {
    max-width: 500px;
}

#chaosInOrder2,
#chaosInOrder3,
#chaosInOrder4,
#chaosInOrder5 {
    height: 22rem;
    max-width: 22rem;
    align-items: end;
    text-align: start;
}

.chaosInOrder div {
    padding: 10px;
    text-align: center;
    margin-bottom: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.chaosInOrder {
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-items: center;
    background: rgb(62, 0, 84);
    background: radial-gradient(circle, rgb(69, 1, 1) 0%, rgba(0, 0, 0, 1) 73%);
}

#chaosInOrder1 h1 {
    font-size: 1.8rem;
    color: #d1e0ff;
    margin-bottom: 10px;
    text-align: center;
}

#chaosInOrder2,
#chaosInOrder3,
#chaosInOrder4,
#chaosInOrder5 {
    position: relative;
    width: 500px;
    height: 450px;
    overflow: hidden;
}


#chaosInOrder2 img,
#chaosInOrder3 img,
#chaosInOrder4 img,
#chaosInOrder5 img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: brightness(50%);
    transition: filter 0.5s ease, transform 0.5s ease;
}

#chaosInOrder2:hover img,
#chaosInOrder3:hover img,
#chaosInOrder4:hover img,
#chaosInOrder5:hover img {
    filter: brightness(70%);
}

#chaosInOrder2 p,
#chaosInOrder3 p,
#chaosInOrder4 p,
#chaosInOrder5 p {
    width: 300px;
    position: absolute;
    transform: translate(-50%, -50%);
    color: white;
    left: 50%;
    z-index: 2;
    text-align: left;
}

#chaosInOrder2 p {
    top: 83%;
}

#chaosInOrder3 p {
    top: 75%;
}

#chaosInOrder4 p {
    top: 68%;
}

#chaosInOrder5 p {
    top: 80%;
}

#chaosInOrder6 {
    display: flex;
    justify-content: center;
    text-align: center;
    background-color: rgb(29, 4, 17);
    padding: 5rem 2rem 2rem 2rem;
    background: rgb(97, 0, 0);
    background: linear-gradient(0deg, rgba(97, 0, 0, 1) 0%, rgba(7, 1, 4, 1) 50%);
}

#chaosInOrder6>* {
    max-width: 900px;
    font-size: x-large;
}

.doTmy {
    padding-top: 5rem;
    height: 45rem;
    background: url('../../src/images/vyznam/dotmy.jpg');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    padding-right: 5rem;
}

.doTmy > p {
    margin-left: 50px;
    max-width: 450px;
    font-size: larger;
}

.doTmy > h1 {
    max-width: 600px;
}

.vitez {
    padding-top: 7rem;
    padding-bottom: 7rem;
    height: 40rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 5rem;
    background-color: rgb(18, 16, 2);
}

.vitezWrap {
    max-width: 550px;
}

.vitez>p {
    margin-left: 50px;
    max-width: 450px;
    font-size: larger;
}

#vitezImg {
    max-width: 500px;
    height: auto;
    filter: brightness(70%);
}


@media (max-width: 768px) {
    .uvod {
        text-align: center;
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .chaosInOrder1_p {
        flex-direction: column;
    }

    .chaosInOrder1_p>p {
        font-size: x-large;
    }

    #chaosInOrder2 p,
    #chaosInOrder3 p,
    #chaosInOrder5 p {
        font-size: x-large;
    }

    #chaosInOrder4 p {
        font-size: large;
    }

    #chaosInOrder2 p {
        top: 75%;
    }

    #chaosInOrder3 p {
        top: 60%;
    }

    #chaosInOrder4 p {
        top: 60%;
    }

    #chaosInOrder5 p {
        top: 65%;
    }

    .doTmy {
      padding-left: 1rem;
    }

    .doTmy p{
        font-size: 21px;
    }

    .vitez {
        min-height: 800px;
        flex-direction: column;
        padding: 20px;
        align-items: center;
    }

    .vitezWrap {
        padding-bottom: 2rem;
    }

    #vitezImg {
        height: 50%;
        width: auto;

    }


}