
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: monospace, "Lucida Console", "Courier New";
  }
  
  .rest {    
    min-height: 700px;
    margin: 0 auto;
    background-color: #b5cada;
  }
  
  input,
  select {
    height: 30px;
    width: 100%;
    border: 3px solid #143d59;
    border-radius: 5px;
    font-size: 15px;
  }
  
  textarea {
    height: 100px;
    width: 100%;
    border: 3px solid #143d59;
    border-radius: 5px;
    font-size: 15px;
  }
  
  button {
    padding: 7px 15px 7px 15px;
    border: 3px solid #143d59;
    border-radius: 5px;
    background-color: #f4b41a;
    font-size: 15px;
    font-weight: bolder;
    cursor: pointer;
  }
  
  button:hover {
    background-color: white;
    transition: 0.3s ease;
  }
  
  .loading{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 10%;
  }
  
  .loading img{
    width: 150px;
  }

  @media (max-width: 768px) {
    p {
      font-size: larger;
    }

  }