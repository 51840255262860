div.indexPage {
  display: flex;
  flex-direction: column;
}

div.intro {
  margin-top: 10rem;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-evenly;
}

#portrait1 {
  border-radius: 50%;
  width: 40%;
  height: auto;
}

#portrait {
  border-width: 8px;
  border-color: rgba(255, 255, 255, 0.785);
  border-style: solid;
  border-radius: 4px;
  width: 30%;
  height: auto;
}

#firstName {
  color: rgb(142, 0, 0);
}

#secondName {
  color: #061017;
}

.aboutPaintings {
  padding: 12rem 0 12rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #061017;
}

.aboutMe {
  background: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url('../../src/images/index2.jpeg');
  background-repeat: no-repeat;
  background-position: right;
  background-size: 35%;
  background-color: black;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 3rem;
  padding: 3rem;
  color: rgb(226, 226, 226);
}

.introText {
  color: white;
  font-size: 2.5rem;
  text-align: left;
  align-self: center;
}

div.aboutMe>#text {
  max-width: 40%;
  align-self: start;
  padding-top: 5rem;
}

.aboutPaintings > iframe {
  height: 75%;
  width: 75%;
  aspect-ratio: 16 / 9;
  border: 5px solid #143d59;
  border-radius: 5px;
}

div.news iframe {
  max-width: 60%;
}


@media (max-width: 768px) {
  .introText {
    padding-left: 5rem;
    font-size: x-large;
  }

  div.news iframe {
    max-width: 100%;
  }

  div.news {
    height: 300px;
  }

  .intro {
    flex-direction: column;
    align-items: center;
    gap: 2rem;
  }

  .introText{
    padding-left: 1rem;
  }

  .aboutPaintings {
    padding: 5rem 0 5rem 0;
  }

  .aboutMe{
     flex-direction: column;
  }

  #portrait {
    width: 90%;
    height: auto;
  }

  div.aboutMe>#text {
    max-width: 100%;
    align-self: start;
    padding-top: 1rem;
  }
}