.singlepainting {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10rem 0 5rem 0;
}

.singlepainting button {
  max-width: 85px;
}

.singlepainting-description {
  max-width: 50%;
  margin: 15px 0;
}

.singlepainting-description > * {
  margin: 5px 0;
}

.bigPainting {
  max-width: fit-content;
  border: 4px solid #f4b41a;
  border-radius: 8px;
}

.singlepainting-additional {
  max-width: 50%;
}

.additionalcover {
  max-width: 100%;
  margin-top: 10px;
}

@media (max-width: 768px) {
  .singlepainting {
    flex-direction: column;
    align-items: center;
  }

  .singlepainting-description,
  .singlepainting-additional {
    max-width: 100%;
    text-align: center;
  }

  .bigPainting {
    width: 100%;
    max-width: none;
  }
}