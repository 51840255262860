header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 1rem 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  background-color: #061017;
  border-bottom: #c88d04 3px solid;
  opacity: .88;
  }
  
  li {
    list-style: none;    
  }
  
  .nav-link {
    color: #c88d04;
    text-decoration: none;
    font-size: 30px;
  }

  header img{
    width: 100px;
    height: 100px;
  }
    
  .navbar {
    max-height: 70px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 24px;
    transition: max-height .5s ease;
  }

  .navbarDown{
    max-height: 36px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 24px;
    transition:max-height .5s ease;
  }
  
  .nav-menu {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    font-size: 18px;
  }
  
  .nav-menu-non {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    font-size: 18px;
  }
  
  .nav-menu-list {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    gap: 20px;
  }
  
  .nav-link {
    transition: 0.7s ease;
  }
  
  .nav-link:hover {
    color: rgb(226, 226, 226);
  }
  
  .nothamburger {
    display: none;
    cursor: pointer;
  }
  
  .bar {
    display: block;
    width: 25px;
    height: 3px;
    margin: 5px auto;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    background-color: #ffb300;
  }
  
  @media (max-width: 768px) {
    .nothamburger {
      display: block;
    }
  
    .hamburger {
      display: block;
    }
  
    .hamburger .bar:nth-child(2) {
      opacity: 0;
    }
  
    .hamburger .bar:nth-child(1) {
      transform: translateY(8px) rotate(45deg);
    }
  
    .hamburger .bar:nth-child(3) {
      transform: translateY(-8px) rotate(-45deg);
    }
  
    .nav-menu {
      position: absolute;
      left: 7%;
      top: 20px;
      gap: 0;
      flex-direction: row;
      align-items: flex-start;
      background-color: #061017;
      width: 60%;
    }
  
    .nav-menu-list {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 0;
    }
  
    .nav-menu-non {
      left: -100%;
      display: none;
    }
  }