.support {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 11rem 1rem 5rem 1rem;
}

.bank {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    gap: 2rem;
    margin-top: 1rem;
}

#pp {
    width: 300px;
    height: auto;
    margin-top: 1rem;
}

#pp:hover {
    transform: scale(1.05);
    transition: 0.5s;
}

.QRcode {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 1rem;
}

.QRcode img {
    width: 200px;
    height: auto;
    margin-top: 1rem;
}

.uvodSupport {
    max-width: 800px;
}

@media (max-width: 768px) {
    .support {
        text-align: center;
    }

    .bank {
        flex-direction: column;
    }
}