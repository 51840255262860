
.paintins-page {
    padding-top: 10rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  div.filtr {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 15px;
    max-width: fit-content;
    margin: 0px 10px 5px 65px;
    padding: 10px;
    border: 4px solid #c88d04;
    border-radius: 8px;
  }
  
  div.filtr div.filtrDate {
    display: flex;
    gap: 10px;
    align-items: center;
  }
  
  button.arrowDown,
  button.arrowUp {
    padding: 0 7px 0 7px;
    font-size: 25px;
  }
  
  div.download {
    margin: 0px 10px 5px 65px;
    display: flex;
    justify-content: flex-start;
    gap: 10px;
    align-items: center;
    max-width: 800px;
  }
  
  div.paintings {
    margin: 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 2rem;
    max-width: 1024px;
  }
  
  div.paintings img {
    max-width: 95%;
    box-shadow: 0 0 0 8px #c88d04;
    border: 3px solid #061017;
    border-radius: 8px;
    margin: 10px;
  }
   
  div.paintings img:hover{
    transform: scale(1.05);
    transition: 0.5s;
}

  div.paintings div.description {
    max-width: 350px;
    margin-left: 55px;
  }
  
  div.paintings div.description > * {
    margin: 10px;
    color: black;
  }
  
  @media (max-width: 1024px) {
    div.paintings {
      display: flex;
      flex-direction: column;
      border-bottom: 1px solid #c88d04;
      margin-bottom: 15px;
      max-width: 95%;
    }
  
    div.paintings div.description {
      margin-left: 10px;
    }
  
    div.filtr {
      margin-left: 10px;
    }
  
    div.download {
      flex-direction: column;
    }
  }