div.books {
  padding-top: 11rem;
  padding-bottom: 10rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

div.book {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1000px;
}

div.book>* {
  padding-bottom: 1rem;
}

div.books img {
  border: #143d59 5px solid;
  border-radius: 3px;
  height: 420px;
  width: auto;
  margin: 10px;
}

div.books iframe {
  width: 60%;
}

div.descriptionbook {
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  gap: 50px;
}

#donio {
  width: 90%;
  height: 620px;
  border: 2px solid #143d59
}

@media (max-width: 768px) {
  #donio {
    width: 100%;
    height: 800px;
  }

  .books {
    padding: 2rem;
  }


  div.descriptionbook {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }


  div.books img {
    max-width: 97%;
  }


}