
footer {  
    background-color: #061017;
    border-top: #c88d04 3px solid;
    height: 250px; 
    color: #c88d04;
   
  }
  
  div.footer-content{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 15px;
  }
  
  footer img {
    max-height: 50px;
    background-color: #f4b41a;
  }
  
  footer img:hover {
    background-color: white;
    transition: 0.3s ease;
  }
  
  .personaldata-link {
    color: #c88d04;
    text-decoration: none;
  }
  
  .personaldata-link:hover {
    color: rgb(226, 226, 226);
    transition: .3s ease;
  }
  
  @media (max-width: 768px) {
    div.footer-content {
     flex-direction: column;
     align-items: center;
     gap: 15px;
     margin-top: 12px;
     padding: 2rem;
    }
  }