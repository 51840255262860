div.contact {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 11rem;
  padding-bottom: 5rem;
}

form.contactform {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: center;
  background-color: #f4b41a;
  border-radius: 10px;
  width: auto;
  height: auto;
  padding: 15px 8px;
  margin-top: 20px;
}

div.contactdescription {
  display: flex;
  align-items: center;
  align-self: center;
  max-width: 600px;
}

#odeslano {
  font-weight: bold;
  color: red;
  right: auto;
  font-size: 25px;
  text-wrap: nowrap;
}

#neodeslano {
  font-weight: bold;
  color: red;
  right: auto;
  font-size: 25px;
  text-wrap: nowrap;
}

.contact-initials {
  display: flex;
  gap: 10px;
}

#contact-button {
  margin-top: 1rem;
}

#contact-button-sending {
  margin-top: 1rem;
  background-color: red;
}

#message {
  margin-bottom: 1rem;
}

.contact>iframe {
  margin: 0;
  padding: 0;
  border: 0px;

}

@media (max-width: 768px) {
  .contact {
    text-align: center;
  }


  .contact-initials {
    display: flex;
    flex-direction: column;
  }

  .contactdescription {
    display: flex;
    flex-direction: column;
  }

  .contact p {
    font-size: x-large;
  }

  form.contactform {
    min-width: 90%;
    min-height: 700px;
  }

  form.contactform * {
    min-width: 85%;
    min-height: 50px;
    border-width: 5px;
  }

  #message {
    min-height: 220px;
  }
}